<template>
  <div class="login-page">
      
  <main>
      <article>
          <section>
              <div class="eart1">
                <div class="container">
                  <div class="login-grid" style="color: rgb(216, 206, 206);">
                    <div class="">
                      <div class="login-description">

                        <div>
                          <div class="mb-4 d-flex align-items-center justify-content-center">
                            <img src="../assets/images/logo_2.png" width="200px">
                          </div>
                            <h1 class="fs1 text-white">
                              <strong>KIJJABIJO YOUTH SACCO</strong>
                            </h1>
                        </div>
                        
                        
                        <p class="fs2 text-center">
                            Keep learning through this valuable and efficient platform in this period. Our teachers are innovating to bring remote lessons to your homes. Access this platform using any device( smartphone, laptop, tablet or PC ).
                        </p>
                      </div>
                    </div>
                    <div class="">
                      <article class="loginSection">
                        <div class="text-center">
                          <h2 style="font-weight: 600;">WORKSPACE</h2>
                          <p>Please enter your details</p>
                        </div>
                        <div class="form-group">
                          <label for="email">Email</label>
                          <input
                            type="text"
                            v-model="email"
                            @keyup.enter="loginUser"
                            name="email"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group">
                          <label htmlFor="password">Password</label>
                          <input
                            type="password"
                            v-model="password"
                            @keyup.enter="loginUser"
                            name="password"
                            class="form-control"
                          />
                          <span
                            @click="password.type=password.type=='password'?'text':'password'"
                            class="fa fa-fw field-icon toggle-password"
                            :class="(password.type=='password')?' fa-eye':' fa-eye-slash'"
                          ></span>
                        </div>
                        <div class="form-group d-flex align-items-center justify-content-between">
                          <div>
                            <input type="checkbox" id="remember-me">
                            <label for="remember-me">Remember Me</label>
                          </div>
                          <div>
                            <p>Forgot password?</p>
                          </div>
                        </div>
                        <div class="form-group">
                          <div
                            v-if="isBusy"
                            class="d-flex justify-content-center"
                          >
                            <ScaleOut />
                          </div>
                          <button
                            class="btn btn-block btn-black"
                            @click="loginUser"
                            v-else
                          >
                            Login
                          </button>
                        </div>
                        <div class="form-group">
                          <button
                            class="btn btn-block btn-yellow"
                            @click="loginUser"
                          >
                            Login with Gmail
                          </button>
                        </div>
                        <div class="text-center mt-5">
                          <small> Affliate Agency <a href="">Create an Account</a> </small>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
          </section>
      </article>

  </main>

  </div>
</template>

<script>
    import ScaleOut from "@/components/scale-out-component.vue";

    export default {
      components: {
        ScaleOut,
      },

      data() {
        return {
          isBusy: false,
          email: "",
          password: "",
        };
      },

      methods: {
        async loginUser() {
          try {
            this.isBusy = true;
            let request = await this.$http.post("company-users/login", {
              email: this.email,
              password: this.password,
            });
            if (
              request.data.success &&
              request.data.message == "Login successful"
            ) {
              this.$notify({
                title: "Login Successful",
                message: "You were successfully logged in.",
                type: "success",
              });
                this.$store.commit("LOGIN_USER", request.data);
              if (request.data.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem("user", JSON.stringify(request.data.token));
                // this.$store.commit("LOGIN_USER", request.data);
                this.$router.replace({ path: "/" });
                location.reload(true);
              }
            } else throw "UNEXPECTED_RESPONSE_RECEIVED";
          } catch (error) {
            if (error.message === "Network Error") {
              return this.$notify({
                title: "Connection Failed",
                message:
                  "Unable to Connect. Please check your Internet Connection and try again.",
                type: "error",
              });
            }

            alert(error);
            //If the API returns other status codes besides 2xx, Handle these responses
            if (error.response) {
              if (error.response.data.message === "Invalid email or password") {
                return this.$notify({
                  title: "Invalid",
                  message: "Invalid email or password",
                  type: "warning",
                });
              }
            } else {
              return this.$notify({
                title: "Login Failed",
                message: "Unable to Complete login now. Please try again.",
                type: "error",
              });
            }
          } finally {
            this.isBusy = false;
          }
        },
      },
    };
</script>

<style scoped>
.login-page {
  min-height: 100vh;
  width: 100%;
  background-color: #EBD6D5;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)), url('../assets/images/login_bg_image.jpeg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  align-items: center;
}

main {
  width: 100%;
}

.login-description {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.login-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
}

.login-grid > div:first-child {
  display: flex; 
  align-items: center;

  border: 2px solid rgba(216, 206, 206, 0.3);;
  background-color: rgba(216, 206, 206, 0.3);
  border-radius: 5px;
  padding: 20px;
}

.loginSection {
  /* width: 40%; */
    background-color: #FFF;
  padding: 50px;
  border-radius: 10px;
  -webkit-box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
  box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
  -webkit-transition: box-shadow 0.5s;
  transition: box-shadow 0.5s;
}

.loginSection:hover {
  -webkit-box-shadow: 0px 0px 1.5px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 1.5px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 1.5px -1px rgba(0, 0, 0, 0.75);
  transition: 1s;
}
.btn-black {
  background-color: rgb(31, 20, 114);
  border-radius: 5px;
  color: white;
}
.btn-yellow {
  background-color: orange;
  border-radius: 5px;
  color: rgb(31, 20, 114);
}
.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  

  .login-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .login-grid > div:first-child {
    display: none;
  }

  .loginSection {
    padding: 50px 10px;
  }
}



main {
    font-family: 'Quicksand', Arial, Helvetica, sans-serif !important;
}

.eart1 {
    position: relative;
    width: 100%;
    /* height: 100vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)), url('../assets/images/login_bg_image.jpeg'); */
    background-size: cover;
    background-position: center;
    padding: 20px;
    display: flex;
    align-items: flex-end;
}

a {
    color: #21CAE9;
}
nav {
    box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.1), 0px 4px 4px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.6);
}

.btn_level {
    border-radius: 3px;
    border: 1px solid #21CAE9;
    background-color: transparent;
    color: white;
    width: 120px;
    transition: .5s;
}

.btn_level:hover {
    background-color: #21CAE9;
    color: white;
    transition: .5s;
}

header {
    font-family: 'Quicksand', Arial, Helvetica, sans-serif !important;
}

button {
    font-weight: 500;
}
/*
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}


/*
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {}


/*
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .eart1 {
        height: 100%;
        padding-bottom: 20px;
    }
}
</style>